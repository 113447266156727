function Home() {
    return (
        <div class="row content homepage">
            <div>
                <h1>Under construction</h1>
                <p>Please check back soon!</p>
            </div>
        </div>
    );
}
export default Home;
