import React from "react";
import "../styles/style.scss";

function Footer() {
    // const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div className="row footer">
            <b>PFOTHERGILL.COM ©2022</b>
        </div>
    );
}
export default Footer;
