import { Typography } from "@material-ui/core";

function About() {
    return (
        <div className="row content container">
            <Typography>
                <h1>About me</h1>
                <p>I'm a developer for Principal Financial Group</p>
                <p>I like to play golf!</p>
            </Typography>
        </div>
    );
}
export default About;
